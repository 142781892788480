import React from "react"
import { graphql, Link } from "gatsby"
import BlogLayout from "../components/blogLayout"
import BlogCard from "../components/BlogCard/BlogCard"
import "./blogroll.css"

const BlogPage = ({ data, pageContext, location }) => {
  const { numPages, currentPageNumber } = pageContext
  return (
    <BlogLayout location={location} seoTitle="Blog">
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <BlogCard key={node.id} data={node} />
      ))}
      <ul className="pagination">
        <h4>strona: </h4>
        {Array.from({ length: numPages }).map((_, i) => (
          <Link
            className={`${currentPageNumber === i + 1 && "current-page"}`}
            key={`page-number-${i + 1}`}
            to={i === 0 ? `/blog/` : `/blog/${i + 1}`}
          >
            <li>{i + 1}</li>
          </Link>
        ))}
      </ul>
    </BlogLayout>
  )
}

export default BlogPage

export const blogrollQuery = graphql`
  query blogrollQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "blog" } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 940, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            date(formatString: "DD-MM-YYYY")
          }
          id
          fields {
            slug
          }
        }
      }
    }
  }
`
